import { BaseModel } from '@/shared/types/base.model';
import Address from '@/shared/types/address.class';
import { File } from '@/shared/types/file.model';
import { Company } from '../companies/company.model';

export class ProjectSettings extends BaseModel {
  constructor(
    public project: string = '',
    public settings: { [key: string]: string | boolean | number } = {},
    public updateAssets = false,
  ) {
    super();
  }
}
