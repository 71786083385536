import Vue from 'vue';
import Vuex from 'vuex';
import { Filter } from '@/shared/types/filter.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import ProjectsService from '@/api/projects/projects.service';
import { Project } from '@/api/projects/project.model';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';
import { ProjectSettings } from '@/api/projects/project-settings.model';

const projectsService = new ProjectsService();

const state = {
  pagination: {},
  all: [],
  full: [],
  current: new Project(),
  settings: new ProjectSettings('', {}),
};

const getters = {
  all: (state: any) => {
    return state.all;
  },
  full: (state: any) => {
    return state.full;
  },
  current: (state: any) => {
    return state.current;
  },
  settings: (state: any) => {
    return state.settings;
  },
  pagination: (state: any) => {
    return state.pagination;
  },
};

const mutations = {
  setAll(state: any, projects: PaginateResult<Project>) {
    Vue.set(state, 'all', projects.docs);
  },
  setFull(state: any, projects: Project[]) {
    Vue.set(state, 'full', projects);
  },
  setCurrent(state: any, project: Project) {
    Vue.set(state, 'current', project);
  },
  setSettings(state: any, projectSettings: ProjectSettings) {
    Vue.set(state, 'settings', projectSettings);
  },
  setPagination(state: any, projects: PaginateResult<Project>) {
    Vue.set(state, 'pagination', projects);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<Project[]> {
    const projects = await projectsService.fetch(payload);
    context.commit('setAll', projects);
    context.commit('setPagination', projects);
    return projects.docs;
  },
  async fetchFull(context: any, payload?: Filter): Promise<Project[]> {
    let allProjects = [];
    let totalLength = 0;
    do {
      const projects = await projectsService.fetch(payload);
      allProjects = allProjects.concat(projects.docs);
      totalLength = projects.totalDocs;
    } while (allProjects.length < totalLength);
    context.commit('setFull', allProjects);
    return allProjects;
  },
  async fetchById(context: any, id: string): Promise<Project> {
    const project = await projectsService.fetchOne(id);
    context.commit('setCurrent', project);
    return project;
  },
  async fetchByToken(context: any, token: string): Promise<Project> {
    const project = await projectsService.fetchByToken(token);
    context.commit('setCurrent', project);
    return project;
  },
  async fetchSettingsByProject(
    context: any,
    id: string,
  ): Promise<ProjectSettings> {
    const settings = await projectsService.fetchSettingsByProject(id);
    context.commit('setSettings', settings);
    return settings;
  },
  async delete(context: any, id: string): Promise<Project> {
    try {
      return await projectsService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async create(context: any, payload: Project): Promise<Project> {
    try {
      return projectsService.create(payload);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async update(context: any, project: Project): Promise<Project> {
    try {
      return await projectsService.update(project._id || '', project);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async updateSettingsByProject(
    context: any,
    settings: ProjectSettings,
  ): Promise<ProjectSettings> {
    try {
      return projectsService.updateSettingsByProject(settings);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
