import Vue from 'vue';
import { BookingExport } from '@/api/booking-exports/booking-export.model';
import BookingExportsService from '@/api/booking-exports/booking-exports.service';
import { Filter } from '@/shared/types/filter.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';

const bookingexportsService = new BookingExportsService();

const state = {
  all: [],
  pagination: {},
};

const getters = {
  all: (state: any) => {
    return state.all;
  },
  pagination: (state: any) => {
    return state.pagination;
  },
};

const mutations = {
  setAll(state: any, bookingexports: PaginateResult<BookingExport>) {
    Vue.set(state, 'all', bookingexports.docs);
  },
  setPagination(state: any, bookingexports: PaginateResult<BookingExport>) {
    Vue.set(state, 'pagination', bookingexports);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<BookingExport[]> {
    const bookingexports = await bookingexportsService.fetch(payload);
    context.commit('setAll', bookingexports);
    context.commit('setPagination', bookingexports);
    return bookingexports.docs;
  },
  async delete(context: any, id: string): Promise<BookingExport> {
    try {
      return await bookingexportsService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async create(context: any, payload: BookingExport): Promise<BookingExport> {
    try {
      return await bookingexportsService.create(payload);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async update(
    context: any,
    bookingexport: BookingExport,
  ): Promise<BookingExport> {
    try {
      return await bookingexportsService.update(
        bookingexport._id || '',
        bookingexport,
      );
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
