import axios from '@/plugins/axios';
import BaseService from '../base-service';
import { SaltoSite } from './salto-site.model';

export default class SaltoSitesService extends BaseService<SaltoSite> {
  get endpoint() {
    return 'salto-sites';
  }

  syncSites(): Promise<void> {
    return axios.post(`${this.endpoint}/sync`);
  }
}
