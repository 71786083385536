import axios from '@/plugins/axios';
import BaseService from '../base-service';
import { SaltoIq } from './salto-iq.model';

export default class SaltoIqsService extends BaseService<SaltoIq> {
  get endpoint() {
    return 'salto-iqs';
  }

  syncIqs(site: string): Promise<void> {
    return axios.post(`${this.endpoint}/sync/${site}`);
  }

  activateForSite(site: string, state: boolean): Promise<void> {
    return axios.post(`${this.endpoint}/activate-for-site/${site}`, { state });
  }

  activate(id: string): Promise<SaltoIq | boolean> {
    return axios
      .post<SaltoIq | boolean>(`${this.endpoint}/activate/${id}`)
      .then(result => result.data);
  }

  activateWithPin(id: string, pin: string): Promise<SaltoIq> {
    return axios
      .post<SaltoIq>(`${this.endpoint}/activate-with-pin/${id}`, { pin })
      .then(result => result.data);
  }

  deactivate(id: string): Promise<SaltoIq> {
    return axios.post(`${this.endpoint}/deactivate/${id}`);
  }
}
