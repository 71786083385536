import axios from '@/plugins/axios';
import { Asset } from './asset.model';
import { Filter } from '@/shared/types/filter.class';
import { sortResults } from '@/shared/util/sort-results.util';
import { filterResults } from '@/shared/util/filter-results.util';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import BaseService from '../base-service';

export default class AssetsService extends BaseService<Asset> {
  get endpoint() {
    return 'assets';
  }

  async mostBooked(params?: Filter): Promise<any[]> {
    const encodedParams = params?.URIEncode();
    let endpoint = `${this.endpoint}/most-booked`;
    if (encodedParams) {
      endpoint += `?${encodedParams}`;
    }
    const response = await axios.get<any[]>(endpoint, {
      params: params?.toParams(),
    });
    return response.data;
  }

  async fetchAllForProjectByToken(
    token: string,
  ): Promise<PaginateResult<Asset>> {
    const endpoint = `${this.endpoint}/project/${token}`;
    const response = await axios.get<PaginateResult<Asset>>(endpoint);

    return response.data;
  }

  async performAction(id: string, action: string): Promise<void> {
    const endpoint = `${this.endpoint}/${id}/action`;
    const response = await axios.patch<void>(endpoint, { action });

    return response.data;
  }
}
