import Vue from 'vue';
import Vuex from 'vuex';
import { Asset } from '@/api/assets/asset.model';
import AssetsService from '@/api/assets/assets.service';
import { Filter } from '@/shared/types/filter.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';

const assetsService = new AssetsService();

const state = {
  current: {},
  all: [],
  pagination: {},
  mostBooked: [],
};

const getters = {
  current: (state: any) => {
    return state.current;
  },
  pagination: (state: any) => {
    return state.pagination;
  },
  all: (state: any) => {
    return state.all;
  },
  mostBooked: (state: any) => {
    return state.mostBooked;
  },
};

const mutations = {
  setMostBooked(state: any, mostBooked: any[]) {
    Vue.set(state, 'mostBooked', mostBooked);
  },
  setPagination(state: any, pagination: any) {
    Vue.set(state, 'pagination', pagination);
  },
  setAll(state: any, assets: PaginateResult<Asset>) {
    Vue.set(state, 'all', assets.docs);
  },
  setCurrent(state: any, asset: Asset) {
    Vue.set(state, 'current', asset);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<Asset[]> {
    const assets = await assetsService.fetch(payload);
    context.commit('setAll', assets);
    context.commit('setPagination', assets);
    return assets.docs;
  },
  async fetchAllForProjectByToken(
    context: any,
    token: string,
  ): Promise<Asset[]> {
    try {
      const assets = await assetsService.fetchAllForProjectByToken(token);
      context.commit('setAll', assets);
      context.commit('setPagination', assets);
      return assets.docs;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
    }
  },
  async fetchById(context: any, id: string): Promise<Asset> {
    const asset = await assetsService.fetchOne(id);
    context.commit('setCurrent', asset);
    return asset;
  },
  async delete(context: any, id: string): Promise<Asset> {
    try {
      return await assetsService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async create(context: any, payload: Asset): Promise<Asset> {
    try {
      return await assetsService.create(payload);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async update(context: any, asset: Asset): Promise<Asset> {
    try {
      return await assetsService.update(asset._id || '', asset);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async mostBooked(context: any, filter: Filter): Promise<any[]> {
    try {
      const mostBooked = await assetsService.mostBooked(filter);
      context.commit('setMostBooked', mostBooked);
      return mostBooked;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async performAction(
    context: any,
    payload: { id: string; action: string },
  ): Promise<void> {
    return assetsService
      .performAction(payload.id, payload.action)
      .then(response => {
        return response;
      })
      .catch(e => {
        context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
          root: true,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
