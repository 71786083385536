import Vue from 'vue';
import { Filter } from '@/shared/types/filter.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import SaltoLocksService from '@/api/salto-locks/salto-locks.service';
import { SaltoLock } from '@/api/salto-locks/salto-lock.model';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';

const saltolocksService = new SaltoLocksService();

const state = {
  pagination: {},
  all: [],
  current: {},
};

const getters = {
  all: (state: any) => {
    return state.all;
  },
  current: (state: any) => {
    return state.current;
  },
  settings: (state: any) => {
    return state.settings;
  },
  pagination: (state: any) => {
    return state.pagination;
  },
};

const mutations = {
  setAll(state: any, saltolocks: PaginateResult<SaltoLock>) {
    Vue.set(state, 'all', saltolocks.docs);
  },
  emptyAll(state: any) {
    Vue.set(state, 'all', []);
  },
  setCurrent(state: any, saltolock: SaltoLock) {
    Vue.set(state, 'current', saltolock);
  },
  setPagination(state: any, saltolocks: PaginateResult<SaltoLock>) {
    Vue.set(state, 'pagination', saltolocks);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<SaltoLock[]> {
    const saltolocks = await saltolocksService.fetch(payload);
    context.commit('setAll', saltolocks);
    context.commit('setPagination', saltolocks);
    return saltolocks.docs;
  },
  async fetchById(context: any, id: string): Promise<SaltoLock> {
    const saltolock = await saltolocksService.fetchOne(id);
    context.commit('setCurrent', saltolock);
    return saltolock;
  },
  async syncLocks(context: any, site: string): Promise<void> {
    try {
      await saltolocksService.syncLocks(site);
    } catch (e) {
      console.log('HEY1');
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
    }
  },
  async delete(context: any, id: string): Promise<SaltoLock> {
    try {
      return await saltolocksService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async create(context: any, payload: SaltoLock): Promise<SaltoLock> {
    try {
      return saltolocksService.create(payload);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async update(context: any, saltolock: SaltoLock): Promise<SaltoLock> {
    try {
      return await saltolocksService.update(saltolock._id || '', saltolock);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async activateAllForSite(
    context: any,
    payload: { site: string; state: boolean },
  ): Promise<void> {
    try {
      return await saltolocksService.activateForSite(
        payload.site,
        payload.state,
      );
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
