import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import auth from './modules/auth.store';
import app from './modules/app.store';
import users from './modules/users.store';
import keywis from './modules/keywis.store';
import assets from './modules/assets.store';
import publicassets from './modules/publicassets.store';
import categories from './modules/categories.store';
import companies from './modules/companies.store';
import projects from './modules/projects.store';
import bookings from './modules/bookings.store';
import publicbookings from './modules/publicbookings.store';
import profiles from './modules/profiles.store';
import payments from './modules/payments.store';
import logs from './modules/logs.store';
import support from './modules/support.store';
import saltoSites from './modules/salto/salto-sites.store';
import saltoIqs from './modules/salto/salto-iqs.store';
import saltoLocks from './modules/salto/salto-locks.store';
import bookingExports from './modules/booking-exports.store';
import personalKeys from './modules/personal-keys.store';

Vue.use(Vuex);

const store: StoreOptions<any> = {
  modules: {
    auth,
    app,
    users,
    assets,
    publicassets,
    keywis,
    categories,
    companies,
    projects,
    bookings,
    publicbookings,
    profiles,
    payments,
    logs,
    support,
    saltoSites,
    saltoIqs,
    saltoLocks,
    bookingExports,
    personalKeys,
  },
};

export default new Vuex.Store(store);
