import axios from '@/plugins/axios';

export default class SupportService {
  get endpoint() {
    return 'support';
  }

  async fetchByEmail(email: string): Promise<any[]> {
    const response = await axios.get<any[]>(`${this.endpoint}/${email}`);
    return response.data;
  }
}
