import Vue from 'vue';
import Vuex from 'vuex';
import { Filter } from '@/shared/types/filter.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import PersonalKeysService from '@/api/personal-keys/personal-keys.service';
import { PersonalKey } from '@/api/personal-keys/personal-key.model';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';

const personalKeysService = new PersonalKeysService();

const state = {
  all: [],
  pagination: {},
};

const getters = {
  all: (state: any) => {
    return state.all;
  },
  pagination: (state: any) => {
    return state.pagination;
  },
};

const mutations = {
  setAll(state: any, personalKeys: PaginateResult<PersonalKey>) {
    Vue.set(state, 'all', personalKeys.docs);
  },
  setPagination(state: any, personalKeys: PaginateResult<PersonalKey>) {
    Vue.set(state, 'pagination', personalKeys);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<PersonalKey[]> {
    const personalKeys = await personalKeysService.fetch(payload);
    context.commit('setAll', personalKeys);
    context.commit('setPagination', personalKeys);
    return personalKeys.docs;
  },
  async delete(context: any, id: string): Promise<PersonalKey> {
    try {
      return await personalKeysService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async create(context: any, payload: PersonalKey): Promise<PersonalKey> {
    try {
      return await personalKeysService.create(payload);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async update(context: any, personalKey: PersonalKey): Promise<PersonalKey> {
    try {
      return await personalKeysService.update(
        personalKey._id || '',
        personalKey,
      );
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
