import Vue from 'vue';
import Vuex from 'vuex';
import { Filter } from '@/shared/types/filter.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import BookingsService from '@/api/publicbookings/bookings.service';
import { Booking } from '@/api/bookings/booking.model';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';

const bookingsService = new BookingsService();

const state = {
  current: {},
  pagination: {},
  all: [],
  statistics: [],
  statusStatistics: [],
};

const getters = {
  current: (state: any) => {
    return state.current;
  },
  pagination: (state: any) => {
    return state.pagination;
  },
  all: (state: any) => {
    return state.all;
  },
  statistics: (state: any) => {
    return state.statistics;
  },
  statusStatistics: (state: any) => {
    return state.statusStatistics;
  },
};

const mutations = {
  setPagination(state: any, pagination: any) {
    Vue.set(state, 'pagination', pagination);
  },
  setCurrent(state: any, booking: Booking) {
    Vue.set(state, 'current', booking);
  },
  setAll(state: any, bookings: PaginateResult<Booking>) {
    Vue.set(state, 'all', bookings.docs);
  },
  setStatistics(state: any, statistics: any[]) {
    Vue.set(state, 'statistics', statistics);
  },
  setStatusStatistics(state: any, statistics: any[]) {
    Vue.set(state, 'statusStatistics', statistics);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<Booking[]> {
    const bookings = await bookingsService.fetch(payload);
    context.commit('setAll', bookings);
    context.commit('setPagination', bookings);
    return bookings.docs;
  },
  async fetchAllForProjectByToken(
    context: any,
    payload: { token: string; filter: Filter },
  ): Promise<Booking[]> {
    try {
      const bookings = await bookingsService.fetchAllForProjectByToken(
        payload.token,
        payload.filter,
      );
      context.commit('setAll', bookings);
      context.commit('setPagination', bookings);
      return bookings.docs;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
    }
  },
  async fetchById(context: any, id: string): Promise<Booking> {
    const booking = await bookingsService.fetchOne(id);
    context.commit('setCurrent', booking);
    return booking;
  },
  async delete(context: any, id: string): Promise<Booking> {
    try {
      return await bookingsService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async create(context: any, payload: Booking): Promise<Booking> {
    try {
      return await bookingsService.create(payload);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async update(context: any, booking: Booking): Promise<Booking> {
    try {
      return await bookingsService.update(booking._id || '', booking);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async start(context: any, id: string): Promise<Booking> {
    try {
      return await bookingsService.start(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async stop(context: any, id: string): Promise<Booking> {
    try {
      return await bookingsService.stop(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async cancel(context: any, id: string): Promise<Booking> {
    try {
      return await bookingsService.cancel(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async fetchStatistics(context: any, payload?: Filter): Promise<any[]> {
    const statistics = await bookingsService.fetchStatistics(payload);
    context.commit('setStatistics', statistics);
    return statistics;
  },
  async fetchStatusStatistics(context: any, payload?: Filter): Promise<any[]> {
    const statistics = await bookingsService.fetchStatusStatistics(payload);
    context.commit('setStatusStatistics', statistics);
    return statistics;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
