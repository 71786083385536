import axios from '@/plugins/axios';
import { Payment } from './payment.model';
import BaseService from '../base-service';
import { Filter } from '@/shared/types/filter.class';
import { PeriodicStatistic } from '@/shared/types/periodic-statistic.model';
import { PaymentStatusStatistic } from './payment-status-statistic.model';

export default class PaymentsService extends BaseService<Payment> {
  get endpoint() {
    return 'payments';
  }

  async fetchByToken(token: string): Promise<Payment> {
    const result = await axios.get<Payment>(`${this.endpoint}/token/${token}`);
    return result.data;
  }

  async generateCheckout(token: string): Promise<Payment> {
    const result = await axios.post<Payment>(
      `${this.endpoint}/generate-checkout/${token}`,
    );
    return result.data;
  }

  async revokeMandate(user: string): Promise<void> {
    const result = await axios.delete<void>(`${this.endpoint}/${user}/mandate`);
    return result.data;
  }

  async fetchStatistics(params?: Filter): Promise<PeriodicStatistic[]> {
    const encodedParams = params?.URIEncode();
    let endpoint = `${this.endpoint}/statistics`;
    if (encodedParams) {
      endpoint += `?${encodedParams}`;
    }
    const response = await axios.get<PeriodicStatistic[]>(endpoint, {
      params: params?.toParams(),
    });
    return response.data;
    1;
  }

  async fetchStatusStatistics(
    params?: Filter,
  ): Promise<PaymentStatusStatistic[]> {
    const encodedParams = params?.URIEncode();
    let endpoint = `${this.endpoint}/status-statistics`;
    if (encodedParams) {
      endpoint += `?${encodedParams}`;
    }
    const response = await axios.get<PaymentStatusStatistic[]>(endpoint, {
      params: params?.toParams(),
    });
    return response.data;
  }
}
