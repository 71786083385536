import Vue from 'vue';
import VueRouter, { RouteConfig, Route } from 'vue-router';
import store from '../store';
import { User } from '@/api/users/user.class';
import { Role } from '@/api/auth/role.enum';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      protected: true,
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/awards',
    name: 'Awards',
    meta: {
      protected: true,
      onlyRoles: [Role.SuperAdmin, Role.CompanyAdmin, Role.ProjectAdmin],
    },
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/Awards.vue'),
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      protected: true,
      onlyRoles: [Role.SuperAdmin, Role.CompanyAdmin, Role.ProjectAdmin],
    },
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/Users.vue'),
  },
  {
    path: '/users/:id',
    name: 'UserDetail',
    meta: {
      protected: true,
      onlyRoles: [Role.SuperAdmin, Role.CompanyAdmin, Role.ProjectAdmin],
    },
    component: () =>
      import(/* webpackChunkName: "users" */ '../views/UserDetail.vue'),
  },
  {
    path: '/assets',
    name: 'Assets',
    meta: {
      protected: true,
      onlyRoles: [Role.SuperAdmin, Role.CompanyAdmin, Role.ProjectAdmin],
    },
    component: () =>
      import(/* webpackChunkName: "assets" */ '../views/Assets.vue'),
  },
  {
    path: '/assets/new',
    name: 'NewAsset',
    meta: {
      protected: true,
      onlyRoles: [Role.SuperAdmin],
    },
    component: () =>
      import(/* webpackChunkName: "assets" */ '../views/AssetDetail.vue'),
  },
  {
    path: '/assets/:id',
    name: 'AssetDetail',
    meta: {
      protected: true,
      onlyRoles: [Role.SuperAdmin, Role.CompanyAdmin, Role.ProjectAdmin],
    },
    component: () =>
      import(/* webpackChunkName: "assets" */ '../views/AssetDetail.vue'),
  },
  {
    path: '/categories',
    name: 'Categories',
    meta: {
      protected: true,
      onlyRoles: [Role.SuperAdmin],
    },
    component: () =>
      import(/* webpackChunkName: "categories" */ '../views/Categories.vue'),
  },
  {
    path: '/keywis',
    name: 'Keywis',
    meta: {
      protected: true,
      onlyRoles: [Role.SuperAdmin],
    },
    component: () =>
      import(/* webpackChunkName: "keywis" */ '../views/Keywis.vue'),
  },
  {
    path: '/keywis/:id',
    name: 'KeywiDetail',
    meta: {
      protected: true,
      onlyRoles: [Role.SuperAdmin],
    },
    component: () =>
      import(/* webpackChunkName: "keywis" */ '../views/KeywiDetail.vue'),
  },
  {
    path: '/profiles',
    name: 'Profiles',
    meta: {
      protected: true,
      onlyRoles: [Role.SuperAdmin],
    },
    component: () =>
      import(/* webpackChunkName: "keywis" */ '../views/Profiles.vue'),
  },
  {
    path: '/companies',
    name: 'Companies',
    meta: {
      protected: true,
      onlyRoles: [Role.SuperAdmin, Role.CompanyAdmin],
    },
    component: () =>
      import(/* webpackChunkName: "companies" */ '../views/Companies.vue'),
  },
  {
    path: '/projects',
    name: 'Projects',
    meta: {
      protected: true,
      onlyRoles: [Role.SuperAdmin, Role.CompanyAdmin, Role.ProjectAdmin],
    },
    component: () =>
      import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
  },
  {
    path: '/projects/:id',
    name: 'Project',
    meta: {
      protected: true,
      onlyRoles: [Role.SuperAdmin, Role.CompanyAdmin, Role.ProjectAdmin],
    },
    component: () =>
      import(/* webpackChunkName: "projects" */ '../views/Project.vue'),
  },
  {
    path: '/calendar/:token',
    name: 'PublicProjectCalendar',
    component: () =>
      import(
        /* webpackChunkName: "projects" */ '../views/PublicProjectCalendar.vue'
      ),
  },
  {
    path: '/projects/:id/calendar',
    name: 'ProjectCalendar',
    meta: {
      protected: true,
      onlyRoles: [Role.SuperAdmin, Role.CompanyAdmin, Role.ProjectAdmin],
    },
    component: () =>
      import(/* webpackChunkName: "projects" */ '../views/ProjectCalendar.vue'),
  },
  {
    path: '/bookings',
    name: 'Bookings',
    meta: {
      protected: true,
      onlyRoles: [
        Role.SuperAdmin,
        Role.CompanyAdmin,
        Role.ProjectAdmin,
        Role.User,
      ],
    },
    component: () =>
      import(/* webpackChunkName: "bookings" */ '../views/Bookings.vue'),
  },
  {
    path: '/personal-keys',
    name: 'PersonalKeys',
    meta: {
      protected: true,
      onlyRoles: [Role.SuperAdmin, Role.CompanyAdmin, Role.ProjectAdmin],
    },
    component: () =>
      import(
        /* webpackChunkName: "personalKeys" */ '../views/PersonalKeys.vue'
      ),
  },
  {
    path: '/salto-sites',
    name: 'SaltoSites',
    meta: {
      protected: true,
      onlyRoles: [Role.SuperAdmin, Role.CompanyAdmin, Role.ProjectAdmin],
    },
    component: () =>
      import(
        /* webpackChunkName: "bookings" */ '../views/salto/SaltoSites.vue'
      ),
  },
  {
    path: '/salto-sites/:id',
    name: 'SaltoSite',
    meta: {
      protected: true,
      onlyRoles: [Role.SuperAdmin, Role.CompanyAdmin, Role.ProjectAdmin],
    },
    component: () =>
      import(/* webpackChunkName: "bookings" */ '../views/salto/SaltoSite.vue'),
  },
  {
    path: '/bookings/:id',
    name: 'BookingDetail',
    meta: {
      protected: true,
      onlyRoles: [
        Role.SuperAdmin,
        Role.CompanyAdmin,
        Role.ProjectAdmin,
        Role.User,
      ],
    },
    component: () =>
      import(/* webpackChunkName: "bookings" */ '../views/BookingDetail.vue'),
  },
  {
    path: '/select-project',
    name: 'SelectProject',
    meta: {
      protected: true,
      noSidebar: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "selectproject" */ '../views/SelectProject.vue'
      ),
  },
  {
    path: '/login',
    name: 'Login',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "reset" */ '../views/PasswordReset.vue'),
  },
  {
    path: '/invited/:token(.*)',
    name: 'Invited',
    meta: {},
    component: () =>
      import(/* webpackChunkName: "invited" */ '../views/Invited.vue'),
  },
  {
    path: '/password-reset/:token(.*)',
    name: 'ExecutePasswordReset',
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "resetexecute" */ '../views/ExecutePasswordReset.vue'
      ),
  },
  {
    path: '/download',
    name: 'DownloadApp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "downloadapp" */ '../views/DownloadApp.vue'),
  },
  {
    path: '/payments',
    name: 'Payments',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      protected: true,
    },
    component: () =>
      import(/* webpackChunkName: "Payments" */ '../views/Payments.vue'),
  },
  {
    path: '/pay/:token(.*)',
    name: 'PayPayment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "paypayment" */ '../views/PayPayment.vue'),
  },
  {
    path: '/forbidden',
    name: 'PageForbidden',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "pageforbidden" */ '../views/PageForbidden.vue'
      ),
  },
  {
    path: '*',
    name: 'PageNotFound',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "pagenotfound" */ '../views/PageNotFound.vue'
      ),
  },
];

const router: VueRouter = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to: Route, from: Route, next: any) => {
  if (!to.meta.protected) {
    return next();
  }
  await store.dispatch('users/fetchLoggedInUser');
  const loggedInUser: User = store.getters['users/loggedInUser'];

  if (!loggedInUser) {
    return next({ name: 'Login' });
  }
  if (to.meta.onlyRoles && !to.meta.onlyRoles.includes(loggedInUser.role)) {
    return next({ name: 'PageForbidden' });
  }

  return next();
});

export default router;
