import Vue from 'vue';
import { Log } from '@/api/logs/log.model';
import LogsService from '@/api/logs/logs.service';
import { Filter } from '@/shared/types/filter.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';

const logsService = new LogsService();

const state = {
  current: {},
  all: [],
  pagination: {},
};

const getters = {
  current: (state: any) => {
    return state.current;
  },
  pagination: (state: any) => {
    return state.pagination;
  },
  all: (state: any) => {
    return state.all;
  },
};

const mutations = {
  setPagination(state: any, pagination: any) {
    Vue.set(state, 'pagination', pagination);
  },
  setAll(state: any, logs: PaginateResult<Log>) {
    Vue.set(state, 'all', logs.docs);
  },
  setCurrent(state: any, log: Log) {
    Vue.set(state, 'current', log);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<Log[]> {
    const logs = await logsService.fetch(payload);
    context.commit('setAll', logs);
    context.commit('setPagination', logs);
    return logs.docs;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
