import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  en: {
    userState0: 'Invited',
    userState1: 'Pending',
    userState2: 'Active',
    userState3: 'Blocked',
    userState4: 'Deleted',
    default: 'Info',
    warning: 'Warninng',
    danger: 'Error',
    success: 'Awesome!',
    SUPER_ADMIN: 'Super Admin',
    COMPANY_ADMIN: 'Company Admin',
    PROJECT_ADMIN: 'Project Admin',
    USER: 'User',
    paymentStatus: {
      paid: 'Paid',
      notCreated: 'Open',
      open: 'Open',
      canceled: 'Open',
      expired: 'Open',
      authorized: 'Pending',
      pending: 'Pending',
      failed: 'Failed',
      archived: 'Archived',
    },
    userPaymentStatus: {
      NEW: 'No activity',
      FIRST_PAYMENT_PENDING: 'Awaiting first payment',
      PAYING: 'Active',
      RENEWAL_REQUIRED: 'Mandate expired',
    },
    userPaymentStatusVariant: {
      NEW: 'secondary',
      FIRST_PAYMENT_PENDING: 'info',
      PAYING: 'primary',
      RENEWAL_REQUIRED: 'warning',
    },
    user: {
      error: {
        passwordDoesNotMatch: 'Passwords do not match',
      },
    },
    'registration-complete': 'Registration complete!',
    'logging-in': 'Logging you in',
  },
};

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});
