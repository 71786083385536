import axios from '@/plugins/axios';
import BaseService from '../base-service';
import { ProjectSettings } from './project-settings.model';
import { Project } from './project.model';

export default class ProjectsService extends BaseService<Project> {
  get endpoint() {
    return 'projects';
  }

  async updateSettingsByProject(
    settings: ProjectSettings,
  ): Promise<ProjectSettings> {
    const { _id, ...data } = settings;
    const response = await axios.put<ProjectSettings>(
      `${this.endpoint}/${settings.project}/settings`,
      data,
    );
    return response.data;
  }

  async fetchSettingsByProject(project: string): Promise<ProjectSettings> {
    const response = await axios.get<ProjectSettings>(
      `${this.endpoint}/${project}/settings`,
    );
    return response.data;
  }

  async fetchByToken(token: string): Promise<Project> {
    const response = await axios.get<Project>(
      `${this.endpoint}/token/${token}`,
    );
    return response.data;
  }
}
