















import Vue from "vue";
import Component from "vue-class-component";
import SideMenu from "@/components/SideMenu.vue";

@Component({ components: { SideMenu } })
export default class SideBarLayout extends Vue {}
