import axios from '@/plugins/axios';
import { LoginResult } from './login-result.class';
import { Login } from './login.class';
import { ResetPassword } from './reset-password.class';
import { Registration } from './registration.model';
import { User } from '../users/user.class';

export default class AuthService {
  endpoint = 'auth';
  async login(loginData: Login): Promise<LoginResult> {
    const response = await axios.post<LoginResult>(
      `${this.endpoint}/login`,
      loginData,
    );
    return response.data;
  }
  async requestResetPassword(email: string): Promise<void> {
    const response = await axios.post<void>(
      `${this.endpoint}/request-password-reset`,
      {
        email,
      },
    );
    return response.data;
  }
  async resetPassword(payload: ResetPassword): Promise<void> {
    const { token, ...params } = payload;
    const response = await axios.post<void>(
      `${this.endpoint}/reset-password/${token}`,
      params,
    );
    return response.data;
  }

  async register(payload: Registration): Promise<User> {
    const { token, ...params } = payload;
    const response = await axios.post<User>(
      `${this.endpoint}/register/${token}`,
      params,
    );
    return response.data;
  }

  async findByInviteToken(inviteToken: string): Promise<User> {
    const response = await axios.get<User>(
      `${this.endpoint}/invited/${inviteToken}`,
    );
    return response.data;
  }

  async getTokenForProject(inviteToken: string): Promise<User> {
    const response = await axios.get<User>(
      `${this.endpoint}/invited/${inviteToken}`,
    );
    return response.data;
  }
}
