import Vue from 'vue';
import { Filter } from '@/shared/types/filter.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import SaltoSitesService from '@/api/salto-sites/salto-sites.service';
import { SaltoSite } from '@/api/salto-sites/salto-site.model';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';

const saltositesService = new SaltoSitesService();

const state = {
  pagination: {},
  all: [],
  current: {},
};

const getters = {
  all: (state: any) => {
    return state.all;
  },
  current: (state: any) => {
    return state.current;
  },
  settings: (state: any) => {
    return state.settings;
  },
  pagination: (state: any) => {
    return state.pagination;
  },
};

const mutations = {
  setAll(state: any, saltosites: PaginateResult<SaltoSite>) {
    Vue.set(state, 'all', saltosites.docs);
  },
  setCurrent(state: any, saltosite: SaltoSite) {
    Vue.set(state, 'current', saltosite);
  },
  setPagination(state: any, saltosites: PaginateResult<SaltoSite>) {
    Vue.set(state, 'pagination', saltosites);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<SaltoSite[]> {
    const saltosites = await saltositesService.fetch(payload);
    context.commit('setAll', saltosites);
    context.commit('setPagination', saltosites);
    return saltosites.docs;
  },
  async fetchById(context: any, id: string): Promise<SaltoSite> {
    const saltosite = await saltositesService.fetchOne(id);
    context.commit('setCurrent', saltosite);
    return saltosite;
  },
  async syncSites(context: any): Promise<void> {
    return saltositesService.syncSites();
  },
  async delete(context: any, id: string): Promise<SaltoSite> {
    try {
      return await saltositesService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async create(context: any, payload: SaltoSite): Promise<SaltoSite> {
    try {
      return saltositesService.create(payload);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async update(context: any, saltosite: SaltoSite): Promise<SaltoSite> {
    try {
      return await saltositesService.update(saltosite._id || '', saltosite);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
