import Vue from 'vue';
import { Profile } from '@/api/profiles/profile.model';
import ProfilesService from '@/api/profiles/profiles.service';
import { Filter } from '@/shared/types/filter.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';

const profilesService = new ProfilesService();

const state = {
  all: [],
  pagination: {},
};

const getters = {
  all: (state: any) => {
    return state.all;
  },
  pagination: (state: any) => {
    return state.pagination;
  },
};

const mutations = {
  setAll(state: any, profiles: PaginateResult<Profile>) {
    Vue.set(state, 'all', profiles.docs);
  },
  setPagination(state: any, profiles: PaginateResult<Profile>) {
    Vue.set(state, 'pagination', profiles);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<Profile[]> {
    const profiles = await profilesService.fetch(payload);
    context.commit('setAll', profiles);
    context.commit('setPagination', profiles);
    return profiles.docs;
  },
  async delete(context: any, id: string): Promise<Profile> {
    try {
      return await profilesService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async create(context: any, payload: Profile): Promise<Profile> {
    try {
      return await profilesService.create(payload);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async update(context: any, profile: Profile): Promise<Profile> {
    try {
      return await profilesService.update(profile._id || '', profile);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
