





































































import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { NavItem } from '../shared/types/nav-item.class';
import { Watch } from 'vue-property-decorator';
import { User } from '../api/users/user.class';
import { Role } from '../api/auth/role.enum';

const authModule = namespace('auth');
const usersModule = namespace('users');

@Component({})
export default class SideMenu extends Vue {
  navItemsAdmin: NavItem[] = [
    // new NavItem('Awards', 'Awards', 'award'),
    new NavItem('Categories', 'Categories', 'tag', 'Category'),
    new NavItem('Companies', 'Companies', 'briefcase', 'Company'),
    new NavItem('Projects', 'Projects', 'map-pin', 'Project'),
    new NavItem('Keywis', 'Keywis', 'hard-drive', 'Keywi'),
    new NavItem('Profiles', 'Profiles', 'radio', 'Profile'),
    new NavItem('Salto', 'SaltoSites', 'lock', 'Salto'),
  ];

  navItems: NavItem[] = [
    new NavItem('Dashboard', 'Home', 'bar-chart-2'),
    new NavItem('Users', 'Users', 'users', 'User'),
    new NavItem('Assets', 'Assets', 'package', 'Asset'),
    new NavItem('Personal keys', 'PersonalKeys', 'pocket', 'PersonalKey'),
    new NavItem('Bookings', 'Bookings', 'clock', 'Booking'),
    new NavItem('Payments', 'Payments', 'dollar-sign', 'Payment'),
  ];

  @usersModule.Getter('loggedInUser')
  user!: User;

  @authModule.Getter('projectName')
  projectName!: string;

  @Watch('user', { immediate: true })
  onUserChanged() {
    this.navItemsAdmin[1].title =
      this.user.role === Role.SuperAdmin ? 'Companies' : 'Company';
    this.navItemsAdmin[2].title =
      this.user.role === Role.SuperAdmin || this.user.role === Role.CompanyAdmin
        ? 'Projects'
        : 'Project';
  }

  get currentRoute() {
    return this.$route.name;
  }

  get currentProjectName() {
    return this.projectName || 'All';
  }

  get navItemsAuth() {
    const ret = [];
    for (const item of this.navItems) {
      if (this.canAccesItem(item.subject)) {
        ret.push(item);
      }
    }
    return ret;
  }

  get navItemsAdminAuth() {
    const ret = [];
    for (const item of this.navItemsAdmin) {
      if (this.canAccesItem(item.subject)) {
        ret.push(item);
      }
    }
    return ret;
  }

  @authModule.Getter('project')
  project!: string;

  @authModule.Mutation('logout')
  clearData!: () => void;

  canAccesItem(subject?: string) {
    if (!subject) {
      return true;
    }
    return this.$can('read', subject);
  }

  logout() {
    this.clearData();
    this.$router.push({ name: 'Login' });
  }
}
