import Vue from 'vue';
import Vuex from 'vuex';
import { Filter } from '@/shared/types/filter.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import CompaniesService from '@/api/companies/companies.service';
import { Company } from '@/api/companies/company.model';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';

const companiesService = new CompaniesService();

const state = {
  all: [],
  pagination: {},
};

const getters = {
  all: (state: any) => {
    return state.all;
  },
  pagination: (state: any) => {
    return state.pagination;
  },
};

const mutations = {
  setAll(state: any, companies: PaginateResult<Company>) {
    Vue.set(state, 'all', companies.docs);
  },
  setPagination(state: any, companies: PaginateResult<Company>) {
    Vue.set(state, 'pagination', companies);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<Company[]> {
    const companies = await companiesService.fetch(payload);
    context.commit('setAll', companies);
    context.commit('setPagination', companies);
    return companies.docs;
  },
  async delete(context: any, id: string): Promise<Company> {
    try {
      return await companiesService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async create(context: any, payload: Company): Promise<Company> {
    try {
      return await companiesService.create(payload);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async update(context: any, company: Company): Promise<Company> {
    try {
      return await companiesService.update(company._id || '', company);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
