import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';
import { User } from '@/api/users/user.class';
import AuthService from '@/api/auth/auth.service';
import { Login } from '@/api/auth/login.class';
import { ResetPassword } from '@/api/auth/reset-password.class';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';
import { Registration } from '@/api/auth/registration.model';
import { Project } from '@/api/projects/project.model';

Vue.use(Vuex);

const authService = new AuthService();

const state = {
  currentLogin: {},
  token: localStorage.getItem('token'),
  project: localStorage.getItem('project'),
  projectName: localStorage.getItem('projectName'),
  refreshToken: localStorage.getItem('refresh-token'),
};

const getters = {
  currentLogin: (state: any) => {
    return state.currentLogin;
  },
  token: (state: any) => {
    return state.token;
  },
  project: (state: any) => {
    return state.project;
  },
  projectName: (state: any) => {
    return state.projectName;
  },
  refreshToken: (state: any) => {
    return state.refreshToken;
  },
  logout: (state: any) => {
    delete state.currentLogin;
    delete state.token;
    delete state.project;
  },
};

const mutations = {
  setCurrentLogin(state: any, user: User) {
    Vue.set(state, 'currentLogin', user);
  },
  setToken(state: any, token: string) {
    localStorage.setItem('token', token);
    Vue.set(state, 'token', token);
  },
  setProject(state: any, project: Project) {
    localStorage.setItem('project', project?._id);
    localStorage.setItem('projectName', project?.name);
    Vue.set(state, 'project', project?._id);
    Vue.set(state, 'projectName', project?.name);
  },
  setRefreshToken(state: any, refreshToken: string) {
    localStorage.setItem('refresh-token', refreshToken);
    Vue.set(state, 'refreshToken', refreshToken);
  },
  logout(state: any) {
    delete state.currentLogin;
    delete state.token;
    delete state.project;
    localStorage.removeItem('token');
    localStorage.removeItem('refresh-token');
    localStorage.removeItem('project');
    localStorage.removeItem('projectName');
    delete axios.defaults.headers.common.Authorization;
  },
};

const actions = {
  async login(context: any, login: Login) {
    try {
      login.scope = 'web';
      const result = await authService.login(login);
      context.commit('setCurrentLogin', result.user);
      context.commit('setToken', result.bearerToken);
      context.commit('setRefreshToken', result.refreshToken);
      axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'token',
      )}`;
      return result;
    } catch (e) {
      context.commit(
        'app/addToast',
        new Toast('Login failed', ToastType.ERROR),
        {
          root: true,
        },
      );
      return false;
    }
  },
  async requestResetPassword(context: any, email: string) {
    return authService.requestResetPassword(email);
  },
  async resetPassword(context: any, payload: ResetPassword) {
    return authService.resetPassword(payload);
  },
  async register(context: any, payload: Registration) {
    return authService.register(payload);
  },
  async findByInviteToken(context: any, inviteToken: string) {
    return authService.findByInviteToken(inviteToken);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
