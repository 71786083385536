import Vue from 'vue';
import Vuex from 'vuex';
import SupportService from '@/api/support/support.service';

const supportService = new SupportService();

const state = {
  all: [],
};

const getters = {
  all: (state: any) => {
    return state.all;
  },
};

const mutations = {
  setAll(state: any, tickets: any[]) {
    Vue.set(state, 'all', tickets);
  },
};

const actions = {
  async fetchByEmail(context: any, email: string): Promise<any[]> {
    const tickets = await supportService.fetchByEmail(email);
    context.commit('setAll', tickets);
    return tickets;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
