import Vue from 'vue';
import { Filter } from '@/shared/types/filter.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import SaltoIqsService from '@/api/salto-iqs/salto-iqs.service';
import { SaltoIq } from '@/api/salto-iqs/salto-iq.model';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';

const saltoiqsService = new SaltoIqsService();

const state = {
  pagination: {},
  all: [],
  current: {},
};

const getters = {
  all: (state: any) => {
    return state.all;
  },
  current: (state: any) => {
    return state.current;
  },
  settings: (state: any) => {
    return state.settings;
  },
  pagination: (state: any) => {
    return state.pagination;
  },
};

const mutations = {
  setAll(state: any, saltoiqs: PaginateResult<SaltoIq>) {
    Vue.set(state, 'all', saltoiqs.docs);
  },
  setCurrent(state: any, saltoiq: SaltoIq) {
    Vue.set(state, 'current', saltoiq);
  },
  setPagination(state: any, saltoiqs: PaginateResult<SaltoIq>) {
    Vue.set(state, 'pagination', saltoiqs);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<SaltoIq[]> {
    const saltoiqs = await saltoiqsService.fetch(payload);
    context.commit('setAll', saltoiqs);
    context.commit('setPagination', saltoiqs);
    return saltoiqs.docs;
  },
  async fetchById(context: any, id: string): Promise<SaltoIq> {
    const saltoiq = await saltoiqsService.fetchOne(id);
    context.commit('setCurrent', saltoiq);
    return saltoiq;
  },
  async syncIqs(context: any, site: string): Promise<void> {
    try {
      await saltoiqsService.syncIqs(site);
    } catch (e) {
      console.log('HEY');
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
    }
  },
  async delete(context: any, id: string): Promise<SaltoIq> {
    try {
      return await saltoiqsService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async create(context: any, payload: SaltoIq): Promise<SaltoIq> {
    try {
      return saltoiqsService.create(payload);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async update(context: any, saltoiq: SaltoIq): Promise<SaltoIq> {
    try {
      return await saltoiqsService.update(saltoiq._id || '', saltoiq);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async activate(context: any, id: string): Promise<SaltoIq | boolean> {
    try {
      return await saltoiqsService.activate(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async activateWithPin(
    context: any,
    payload: { iq: string; pin: string },
  ): Promise<SaltoIq | boolean> {
    try {
      return await saltoiqsService.activateWithPin(payload.iq, payload.pin);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async deactivate(context: any, id: string): Promise<SaltoIq> {
    try {
      return await saltoiqsService.deactivate(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async activateAllForSite(
    context: any,
    payload: { site: string; state: boolean },
  ): Promise<void> {
    try {
      return await saltoiqsService.activateForSite(payload.site, payload.state);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
