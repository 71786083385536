import axios from '@/plugins/axios';
import BaseService from '../base-service';
import { SaltoLock } from './salto-lock.model';

export default class SaltoLocksService extends BaseService<SaltoLock> {
  get endpoint() {
    return 'salto-locks';
  }

  syncLocks(site: string): Promise<void> {
    return axios.post(`${this.endpoint}/sync/${site}`);
  }
  activateForSite(site: string, state: boolean): Promise<void> {
    return axios.post(`${this.endpoint}/activate/${site}`, { state });
  }
}
