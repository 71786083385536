import Vue from 'vue';
import Vuex from 'vuex';
import { User } from '@/api/users/user.class';
import UsersService from '@/api/users/users.service';
import { Filter } from '@/shared/types/filter.class';
import { UserState } from '@/api/users/user-state.enum';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import { Invite } from '@/api/users/invite.model';

const usersService = new UsersService();

const state = {
  loggedInUser: null,
  all: [],
  pagination: {},
  current: {},
  logs: [],
  paginationLogs: {},
  mostBooked: [],
};

const getters = {
  mostBooked: (state: any) => {
    return state.mostBooked;
  },
  pagination: (state: any) => {
    return state.pagination;
  },
  loggedInUser: (state: any) => {
    return state.loggedInUser;
  },
  all: (state: any) => {
    return state.all;
  },
  logs: (state: any) => {
    return state.logs;
  },
  paginationLogs: (state: any) => {
    return state.paginationLogs;
  },
  current: (state: any) => {
    return state.current;
  },
};

const mutations = {
  setMostBooked(state: any, mostBooked: any[]) {
    Vue.set(state, 'mostBooked', mostBooked);
  },
  setPagination(state: any, pagination: any) {
    Vue.set(state, 'pagination', pagination);
  },
  setLoggedInUser(state: any, loggedInUser: User) {
    Vue.set(state, 'loggedInUser', loggedInUser);
  },
  setAll(state: any, users: PaginateResult<User>) {
    Vue.set(state, 'all', users.docs);
  },
  setCurrent(state: any, user: User) {
    Vue.set(state, 'current', user);
  },
  setLogs(state: any, logs: PaginateResult<any>) {
    Vue.set(state, 'logs', logs.docs);
    Vue.set(state, 'paginationLogs', logs);
  },
};

const actions = {
  async fetchLoggedInUser(context: any) {
    let loggedInUser: User | undefined;
    try {
      loggedInUser = await usersService.getLoggedInUser();
      context.commit('setLoggedInUser', loggedInUser);
    } catch (e) {
      console.log('e');
    }
    return loggedInUser;
  },

  async fetchById(context: any, id: string): Promise<User> {
    const user = await usersService.fetchOne(id);
    context.commit('setCurrent', user);
    return user;
  },
  async fetchAll(context: any, payload?: Filter): Promise<User[]> {
    const users = await usersService.fetch(payload);
    context.commit('setPagination', users);
    context.commit('setAll', users);
    return users.docs;
  },
  async acceptUser(context: any, user: User): Promise<User> {
    user.state = UserState.Active;
    return await usersService.acceptUser(user);
  },
  async inviteUsers(context: any, invite: Invite): Promise<User> {
    try {
      return await usersService.inviteUsers(invite);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async delete(context: any, id: string): Promise<User> {
    try {
      return usersService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async update(context: any, user: User): Promise<User> {
    try {
      delete user.mandateId;
      const userRet = await usersService.update(user._id || '', user);
      context.commit('setCurrent', userRet);
      return userRet;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async block(context: any, id: string): Promise<User> {
    try {
      const user = await usersService.block(id);
      context.commit('setCurrent', user);
      return user;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async unblock(context: any, id: string): Promise<User> {
    try {
      const user = await usersService.unblock(id);
      context.commit('setCurrent', user);
      return user;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async logs(context: any, filter: Filter): Promise<PaginateResult<any>> {
    try {
      const id: string = filter.firstForKey('user').value as string;
      filter.removeForKey('user');
      const logs = await usersService.logs(id, filter);
      context.commit('setLogs', logs);
      return logs;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async mostBooked(context: any, filter: Filter): Promise<any[]> {
    try {
      const mostBooked = await usersService.mostBooked(filter);
      context.commit('setMostBooked', mostBooked);
      return mostBooked;
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
