import Vue from 'vue';
import App from './App.vue';
// import './registerServiceWorker';
import router from './router';
import store from './store';
import './plugins/bootstrap-vue';
import './plugins/vee-validate';
import './plugins/ability.plugin';
import './plugins/qrcode.plugin';
import './plugins/flatpickr.plugin';
import './plugins/vue-select.plugin';
import './plugins/gmaps.plugin';
import i18n from './plugins/i18n.plugin';

import SideBar from './layouts/SideBar.vue';
import NoSidebar from './layouts/NoSideBar.vue';
Vue.component('sidebar-layout', SideBar);
Vue.component('no-sidebar-layout', NoSidebar);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
