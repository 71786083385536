import Vue from 'vue';
import { KeyWi } from '@/api/keywis/keywi.model';
import KeywisService from '@/api/keywis/keywis.service';
import { Filter } from '@/shared/types/filter.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';

const keywisService = new KeywisService();

const state = {
  all: [],
  pagination: {},
  current: {},
};

const getters = {
  all: (state: any) => {
    return state.all;
  },
  pagination: (state: any) => {
    return state.pagination;
  },
  current: (state: any) => {
    return state.current;
  },
};

const mutations = {
  setPagination(state: any, pagination: any) {
    Vue.set(state, 'pagination', pagination);
  },
  setAll(state: any, keywis: PaginateResult<KeyWi>) {
    Vue.set(state, 'all', keywis.docs);
  },
  emptyAll(state: any) {
    Vue.set(state, 'all', []);
  },
  setCurrent(state: any, keywi: KeyWi) {
    Vue.set(state, 'current', keywi);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<KeyWi[]> {
    const keywis = await keywisService.fetch(payload);
    context.commit('setAll', keywis);
    context.commit('setPagination', keywis);

    return keywis.docs;
  },
  async fetchById(context: any, id: string): Promise<KeyWi> {
    const keywi = await keywisService.fetchOne(id);
    context.commit('setCurrent', keywi);
    return keywi;
  },
  async delete(context: any, id: string): Promise<KeyWi> {
    try {
      return await keywisService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async create(context: any, payload: KeyWi): Promise<KeyWi> {
    try {
      return await keywisService.create(payload);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async update(context: any, keywi: KeyWi): Promise<KeyWi> {
    try {
      return await keywisService.update(keywi._id || '', keywi);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
