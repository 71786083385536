




















import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';

import { User } from './api/users/user.class';
import { defineRules } from './shared/define-rules';

const appModule = namespace('app');
const usersModule = namespace('users');

// The @Component decorator indicates the class is a Vue component
@Component({})
export default class App extends Vue {
  @usersModule.Getter('loggedInUser')
  user!: User;

  @appModule.Getter('isLoading')
  isLoading!: (id: string) => boolean;

  @appModule.Mutation('setAppContext')
  setAppContext!: (vm: Vue) => void;

  @Watch('user')
  onUserChanged() {
    this.$ability.update(defineRules(this.user).rules);
  }

  get layout() {
    if (
      this.$route.meta &&
      this.$route.meta.protected &&
      !this.$route.meta.noSidebar
    ) {
      return 'sidebar-layout';
    }
    return 'no-sidebar-layout';
  }

  created() {
    this.setAppContext(this);
  }
}
