import axios from '@/plugins/axios';
import { User } from './user.class';
import BaseService from '../base-service';
import { Invite } from './invite.model';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import { Filter } from '@/shared/types/filter.class';

export default class UsersService extends BaseService<User> {
  get endpoint() {
    return 'users';
  }

  async getLoggedInUser(): Promise<User> {
    const response = await axios.get<User>(`${this.endpoint}/me`);
    return response.data;
  }

  async acceptUser(user: User): Promise<User> {
    const response = await axios.put<User>(
      `${this.endpoint}/${user._id}/accept`,
    );
    return response.data;
  }

  async inviteUsers(invite: Invite): Promise<User> {
    const response = await axios.post<User>(`${this.endpoint}/invite`, invite);
    return response.data;
  }
  async resendInvite(id: string): Promise<void> {
    const response = await axios.post<void>(
      `${this.endpoint}/${id}/resend-invite`,
    );
  }

  async block(id: string): Promise<User> {
    const response = await axios.post<User>(`${this.endpoint}/${id}/block`);
    return response.data;
  }

  async unblock(id: string): Promise<User> {
    const response = await axios.post<User>(`${this.endpoint}/${id}/unblock`);
    return response.data;
  }

  async logs(id: string, params?: Filter): Promise<PaginateResult<any>> {
    const encodedParams = params?.URIEncode();
    let endpoint = `${this.endpoint}/logs`;
    if (encodedParams) {
      endpoint += `?${encodedParams}`;
    }
    const response = await axios.get<any>(endpoint, {
      params: params?.toParams(),
    });
    return response.data;
  }

  async mostBooked(params?: Filter): Promise<any[]> {
    const encodedParams = params?.URIEncode();
    let endpoint = `${this.endpoint}/most-booked`;
    if (encodedParams) {
      endpoint += `?${encodedParams}`;
    }
    const response = await axios.get<any[]>(endpoint, {
      params: params?.toParams(),
    });
    return response.data;
  }
}
