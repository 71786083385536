import { BaseModel } from '@/shared/types/base.model';
import Address from '@/shared/types/address.class';
import { File } from '@/shared/types/file.model';
import { Company } from '../companies/company.model';

export class Project extends BaseModel {
  constructor(
    public name?: string,
    public description?: string,
    public email?: string,
    public address: Address = new Address(),
    public image?: string | File,
    public company?: Company,
    public formUrl?: string,
    public formTitle?: string,
    public formDescription?: string,
    public token?: string,
    public showBookingsPublic?: boolean,
  ) {
    super();
  }
}
