import axios from '@/plugins/axios';
import { Filter } from '@/shared/types/filter.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import BaseService from '../base-service';
import { Booking } from '../bookings/booking.model';

export default class BookingsService extends BaseService<Booking> {
  get endpoint() {
    return 'bookings';
  }

  async fetchAllForProjectByToken(
    token: string,
    params: Filter,
  ): Promise<PaginateResult<Booking>> {
    const encodedParams = params.URIEncode();
    let endpoint = `${this.endpoint}/publicproject/${token}`;
    if (encodedParams) {
      endpoint += `?${encodedParams}`;
    }
    const response = await axios.get<PaginateResult<Booking>>(endpoint, {
      params: params.toParams(),
    });

    return response.data;
  }

  async fetchStatistics(params?: Filter): Promise<any[]> {
    const encodedParams = params?.URIEncode();
    let endpoint = `${this.endpoint}/statistics`;
    if (encodedParams) {
      endpoint += `?${encodedParams}`;
    }
    const response = await axios.get<any[]>(endpoint, {
      params: params?.toParams(),
    });
    return response.data;
    1;
  }

  async fetchStatusStatistics(params?: Filter): Promise<any[]> {
    const encodedParams = params?.URIEncode();
    let endpoint = `${this.endpoint}/status-statistics`;
    if (encodedParams) {
      endpoint += `?${encodedParams}`;
    }
    const response = await axios.get<any[]>(endpoint, {
      params: params?.toParams(),
    });
    return response.data;
    1;
  }

  async countMonth(filter: Filter): Promise<number> {
    const encodedParams = filter?.URIEncode();
    let endpoint = `${this.endpoint}/count-month`;
    if (encodedParams) {
      endpoint += `?${encodedParams}`;
    }
    const response = await axios.get<number>(endpoint);
    return response.data;
  }

  async start(id: string): Promise<Booking> {
    const response = await axios.put<Booking>(`${this.endpoint}/${id}/start`);
    return response.data;
  }

  async stop(id: string): Promise<Booking> {
    const response = await axios.put<Booking>(
      `${this.endpoint}/${id}/stop?force=true`,
    );
    return response.data;
  }

  async cancel(id: string): Promise<Booking> {
    const response = await axios.put<Booking>(`${this.endpoint}/${id}/cancel`);
    return response.data;
  }
}
