import { AbilityBuilder, Ability } from '@casl/ability';
import { User } from '@/api/users/user.class';
import { Role } from '@/api/auth/role.enum';

export function defineRules(user?: User) {
  const { can, rules } = AbilityBuilder.extract();

  if (!user) {
    return new Ability(rules);
  }
  can('is', user?.role);

  if (user?.role === Role.SuperAdmin) {
    can('manage', 'all');
  } else if (user?.role === Role.CompanyAdmin) {
    can('read', 'Company');
    can('update', 'Company');
    can('manage', 'User');
    can('read', 'Asset');
    can('manage', 'Project');
    can('manage', 'Booking');
    can('read', 'Payment');
  } else if (user?.role === Role.ProjectAdmin) {
    can('manage', 'User');
    can('read', 'Asset');
    can('read', 'Project');
    can('update', 'Project');
    can('manage', 'Booking');
    can('read', 'Payment');
  } else if (user?.role === Role.User) {
    can('read', 'Booking');
    can('read', 'Payment');
  }

  return new Ability(rules);
}
