import Vue from 'vue';
import { Filter } from '@/shared/types/filter.class';
import { PaginateResult } from '@/shared/types/paginate-result.class';
import PaymentsService from '@/api/payments/payments.service';
import { Payment } from '@/api/payments/payment.model';
import Toast from '@/shared/types/toast.class';
import { ToastType } from '@/shared/types/toast-type.enum';

const paymentsService = new PaymentsService();

const state = {
  current: {},
  pagination: {},
  all: [],
  statistics: [],
  statusStatistics: [],
};

const getters = {
  current: (state: any) => {
    return state.current;
  },
  all: (state: any) => {
    return state.all;
  },
  pagination: (state: any) => {
    return state.pagination;
  },
  statistics: (state: any) => {
    return state.statistics;
  },
  statusStatistics: (state: any) => {
    return state.statusStatistics;
  },
};

const mutations = {
  setCurrent(state: any, payment: Payment) {
    Vue.set(state, 'current', payment);
  },
  setAll(state: any, payments: PaginateResult<Payment>) {
    Vue.set(state, 'all', payments.docs);
  },
  setPagination(state: any, payments: PaginateResult<Payment>) {
    Vue.set(state, 'pagination', payments);
  },
  setStatistics(state: any, statistics: any[]) {
    Vue.set(state, 'statistics', statistics);
  },
  setStatusStatistics(state: any, statistics: any[]) {
    Vue.set(state, 'statusStatistics', statistics);
  },
};

const actions = {
  async fetchAll(context: any, payload?: Filter): Promise<Payment[]> {
    const payments = await paymentsService.fetch(payload);
    context.commit('setAll', payments);
    context.commit('setPagination', payments);
    return payments.docs;
  },
  async generateCheckout(context: any, token: string): Promise<Payment> {
    const payment = await paymentsService.generateCheckout(token);
    context.commit('setCurrent', payment);
    return payment;
  },
  async fetchByToken(context: any, token: string): Promise<Payment> {
    const payment = await paymentsService.fetchByToken(token);
    context.commit('setCurrent', payment);
    return payment;
  },
  async delete(context: any, id: string): Promise<Payment> {
    try {
      return await paymentsService.delete(id);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async create(context: any, payload: Payment): Promise<Payment> {
    try {
      return paymentsService.create(payload);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async update(context: any, payment: Payment): Promise<Payment> {
    try {
      return await paymentsService.update(payment._id || '', payment);
    } catch (e) {
      context.commit('app/addToast', new Toast(e.message, ToastType.ERROR), {
        root: true,
      });
      throw e;
    }
  },
  async fetchStatistics(context: any, payload?: Filter): Promise<any[]> {
    const statistics = await paymentsService.fetchStatistics(payload);
    context.commit('setStatistics', statistics);
    return statistics;
  },
  async fetchStatusStatistics(context: any, payload?: Filter): Promise<any[]> {
    const statistics = await paymentsService.fetchStatusStatistics(payload);
    context.commit('setStatusStatistics', statistics);
    return statistics;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
